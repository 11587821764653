import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Wooden Letters",
  "author": "Stefan Huber",
  "date": "2018-11-04",
  "layout": "post",
  "draft": true,
  "path": "/articles/wooden-letters/",
  "tags": ["TagOne", "TagTwo"],
  "description": "bla bla bla... "
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "cnc"
    }}>{`CNC`}</h2>
    <p>{`If you produce your own wood type. It's best to take pear wood («Stirnholz») `}<sup parentName="p" {...{
        "id": "fnref-pear"
      }}><a parentName="sup" {...{
          "href": "#fn-pear",
          "className": "footnote-ref"
        }}>{`pear`}</a></sup>{`.
I also saw good examples made with «Filmbeschichtete Birkensperrholzplatte» (Formica/Resopal) `}<sup parentName="p" {...{
        "id": "fnref-p98a"
      }}><a parentName="sup" {...{
          "href": "#fn-p98a",
          "className": "footnote-ref"
        }}>{`P98a`}</a></sup></p>

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-pear"
        }}>{`I saw 2017 examplex made by Marko in Ljubljana. Awesome!`}<a parentName="li" {...{
            "href": "#fnref-pear",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-p98a"
        }}>{`I saw 2016 examplex made by `}<a parentName="li" {...{
            "href": "https://www.p98a.com/"
          }}>{`P98a`}</a>{` in Berlin.`}<a parentName="li" {...{
            "href": "#fnref-p98a",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      